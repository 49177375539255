body {
  font-family: var(--font-family);
  background-color: var(--surface-ground);
  color: var(--text-color);
  padding: 0;
  margin: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.status-indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: auto;

  &.active {
    background-color: var(--green-500);
  }
  &.not-active {
    background-color: var(--red-600);
  }
}
.load-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}