@import "./colors";

.w-full {
  .p-dropdown, .p-calendar {
    width: 100%;
  }
}

input {
  &.ng-invalid.ng-touched, &.ng-invalid.ng-dirty {
      border-color: $error-color !important;
  }
}

p-dropdown {
  &.ng-invalid.ng-touched, &.ng-invalid.ng-dirty {
      .p-dropdown {
        border-color: $error-color !important;
      }
  }
}

.alert {
  color: $error-color;
}

.delete-button {
  visibility: hidden;
}

.table-row:hover {
  .delete-button {
      visibility: visible;
  }
}