// Libraries
@import '../node_modules/primeng/resources/themes/saga-blue/theme.css';
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/quill/dist/quill.snow.css';

// Custom styles
@import 'assets/scss/common';
@import 'assets/scss/table';
@import 'assets/scss/form';